.auth-layout__container {
    background-image: url('../../assets/images/toppan_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 40px 40px; */
    /* background-image:
    linear-gradient(to right, rgba(128, 128, 128, 0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.2) 1px, transparent 1px); */
    min-height: 100vh;
    position: relative;
    padding: 1.5rem;
}

.auth-layout__layout-wrapper {
    margin-top: 2rem;
    margin-left: 4rem;
}

.auth-layout__logo {
    height: 64px;
}
